header#header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  box-sizing: border-box;
  padding: 30px;
  div {
    img {
      height: 60px;
    }
  }

  @media (max-width: 575px) {
    padding: 15px;
    div {
      img {
        height: 40px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    padding: 15px;
    div {
      img {
        height: 40px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    padding: 30px;
    div {
      img {
        height: 60px;
      }
    }
  }
}
