div.fields {
  form {
    display: grid;
    box-sizing: border-box;
    grid-gap: 15px;
    div.x2 {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    fieldset {
      display: grid;
      box-sizing: border-box;

      label {
        font-size: 15px;
        line-height: 180%;
        color: #ffffff;
      }
      input {
        border: 0;
        font-size: 17px;
        padding: 12px 15px;
        box-sizing: border-box;
        width: 100%;
      }
      button {
        width: 130px;

        border: 0;
        border-radius: 5px;
        font-size: 18px;
        padding: 13px 15px;
        cursor: pointer;
        background-color: #fec700;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }
}
