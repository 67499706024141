footer {
  display: grid;
  background-color: #f5f5f5;

  div {
    display: grid;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    line-height: 150%;
  }
  div:nth-child(2) {
    text-align: center;
    img:first-child {
      margin-right: 50px;
    }
  }
  div:nth-child(3) {
    a {
      margin-right: 10px;
      display: inline-block;
    }
    img:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    text-align: center;
    div:nth-child(2) {
      img {
        height: 50px;
      }
    }
    div:nth-child(3) {
      img {
        height: 30px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: 1fr;
    text-align: center;
    div:nth-child(2) {
      img {
        height: 50px;
      }
    }
    div:nth-child(3) {
      img {
        height: 30px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    grid-template-columns: auto 1fr auto;
    div:nth-child(2) {
      img {
        height: 80px;
      }
    }
    div:nth-child(3) {
      img {
        height: 50px;
      }
    }
  }
}
