section#about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  div.about {
    display: grid;
    align-items: center;
    article {
      margin: auto;
      h1 {
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 300;
        display: block;
        margin-bottom: 10px;
      }
      span {
        height: 2px;
        background-color: #e30e18;
        width: 50px;
        display: block;
      }
      p {
        margin-top: 20px;
        letter-spacing: 1px;
        line-height: 150%;
      }
    }
  }
  div.links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 5px;
    div {
      box-sizing: border-box;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
    }
    a {
      background-color: #e30e18;
      padding: 15px 20px;
      display: block;
      font-size: 20px;
      text-decoration: none;
      color: #ffffff;
      text-align: center;
    }
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    div.about {
      padding: 25px;
      article {
        width: 100%;
      }
    }
    div.links {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      iframe {
        height: 300px;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: 1fr;
    div.about {
      padding: 25px;
      article {
        width: 100%;
      }
    }
    div.links {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      iframe {
        height: 300px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    div.about {
      padding: 35px;
      article {
        width: 70%;
      }
    }
    div.links {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      iframe {
        height: 100%;
      }
    }
  }
}
