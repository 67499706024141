section#benefits {
  display: grid;

  div:first-child {
    img {
      width: 100%;
    }
  }
  div:last-child {
    display: grid;
    align-items: center;
    background-color: #f5f5f5;
    article {
      width: 80%;
      margin: auto;
      h1 {
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 300;
        display: block;
        margin-bottom: 10px;
      }
      span {
        height: 2px;
        background-color: #e30e18;
        width: 50px;
        display: block;
      }
      ul {
        margin-top: 30px;
        li {
          margin-left: 20px;
          font-weight: 600;
          margin-bottom: 10px;
          font-size: 16px;
          letter-spacing: 1px;
          line-height: 160%;
        }
      }
    }
  }
  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    div:last-child {
      padding: 40px 0;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: 1fr;
    div:last-child {
      padding: 40px 0;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
}
