h1.heading {
  padding: 30px 30px;
  font-size: 35px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  color: #e30e18;
  @media (max-width: 575px) {
    font-size: 25px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 25px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    font-size: 35px;
  }
}

section#course {
  display: grid;
  div.about {
    margin: 50px;
    article {
      margin: auto;
      h1 {
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 300;
        display: block;
        margin-bottom: 10px;
      }
      span {
        height: 2px;
        background-color: #e30e18;
        width: 50px;
        display: block;
      }
      p {
        margin-top: 20px;
        letter-spacing: 1px;
        line-height: 150%;
      }
      table {
        margin-top: 50px;

        tr {
          border: 10px solid #ffffff;
          border-left: none;
          td {
            padding: 20px;
            font-size: 18px;
            font-weight: 600;
          }
          td:first-child {
            background-color: #e30e18;
            color: #ffffff;
          }
        }
      }
    }
  }
  div.modules {
    display: grid;
    align-items: center;
    article {
      background-color: #e30e18;
      box-sizing: border-box;

      padding: 30px 40px;
      margin: auto;
      h1 {
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 300;
        display: block;
        color: #ffffff;
        margin-bottom: 30px;
      }
      h4 {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1px;
        display: block;
        color: #ffffff;
        margin-top: 20px;
        margin-bottom: 5px;
      }
      ul {
        li {
          list-style: none;
          font-size: 17px;
          line-height: 150%;
          color: #ffffff;
        }
      }
    }
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
    div.about {
      article {
        width: 100%;
      }
    }
    div.modules {
      article {
        width: 100%;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (min-width: 992px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    div.about {
      article {
        width: 550px;
      }
    }
    div.modules {
      article {
        width: 500px;
      }
    }
  }
}
