section#slide {
  background-color: aqua;
  background-image: url("../img/london-school-of-commerce-malta.jpg");
  background-position: center center;
  background-size: cover;
  div.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    div.content {
      display: grid;
      align-items: center;
      article {
        box-sizing: border-box;
        display: block;
        margin: auto;
        h1 {
          letter-spacing: 1px;
          font-weight: 300;
          text-transform: uppercase;
          color: #ffffff;
          line-height: 150%;
        }
        span {
          display: block;
          height: 2px;
          width: 100px;
          background-color: #ffffff;
        }
        p {
          font-weight: 400;
          letter-spacing: 1px;
          color: #ffffff;
          line-height: 150%;
        }
        a {
          display: inline-block;
          border: 2px solid #ffffff;
          text-decoration: none;
          color: #ffffff;
          &:hover {
            background-color: #e30e18;
            border: 2px solid #e30e18;
          }
        }
      }
    }
    div.form {
      display: grid;
      align-items: center;
      box-sizing: border-box;

      article {
        background-color: #e30e18;
        border-radius: 0 15% 0 0;
        margin: auto;
        box-sizing: border-box;
        padding: 30px 40px;
        h1 {
          font-size: 40px;
          letter-spacing: 1px;
          font-weight: 300;
          color: #ffffff;
          display: block;
          margin-bottom: 30px;
        }
        p {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 130%;
          margin: 20px 0;
        }
        @import "form";
      }
    }
    @media (max-width: 575px) {
      grid-template-columns: 1fr;
      div.content {
        article {
          width: 90%;
          margin: 30px auto;
          h1 {
            font-size: 20px;
          }
          span {
            margin: 15px 0;
          }
          p {
            font-size: 14px;
          }
          a {
            padding: 10px 12px;
            margin-top: 15px;
            font-size: 14px;
          }
        }
      }
      div.form {
        margin: 30px 0;
        article {
          width: 90%;
        }
      }
    }

    @media (min-width: 576px) and (max-width: 767px) {
      grid-template-columns: 1fr;
      div.content {
        article {
          width: 90%;
          margin: 30px auto;
          h1 {
            font-size: 20px;
          }
          span {
            margin: 15px 0;
          }
          p {
            font-size: 14px;
          }
          a {
            padding: 10px 12px;
            margin-top: 15px;
            font-size: 14px;
          }
        }
      }
      div.form {
        margin: 30px 0;
        article {
          width: 90%;
        }
      }
    }

    @media (min-width: 768px) and (max-width: 991px) {
    }

    @media (min-width: 992px) and (max-width: 1199px) {
    }

    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      div.content {
        article {
          width: 550px;
          h1 {
            font-size: 40px;
          }
          span {
            margin: 35px 0;
          }
          p {
            font-size: 18px;
          }
          a {
            padding: 12px 18px;
            margin-top: 35px;
            font-size: 18px;
          }
        }
      }
      div.form {
        margin: 100px 0;
        article {
          width: 500px;
        }
      }
    }
  }
}
