@import "reset";

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

@import "header";
@import "slide";
@import "course";
@import "benefits";
@import "about";
@import "contact";
